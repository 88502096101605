import fetch from 'auth/FetchInterceptor'

const urlKey = '/forms';

export default class FormService {

  static async createObject(data) {
    return await fetch({
      url: urlKey,
      method: 'post',
      data: data,
    })
  }
  static async getObjects(params) {
    return await fetch({
      url: urlKey,
      method: 'get',
      params: params
    })
  }
  static async getObject(id) {
    return await fetch({
      url: urlKey + `/${id}`,
      method: 'get',
    })
  }
  static async updateObject(id, data) {
    return await fetch({
      url: urlKey + `/${id}`,
      method: 'put',
      data: data,
    })
  }
  static async deleteObject(id) {
    return await fetch({
      url: urlKey + `/${id}`,
      method: 'delete',
    })
  }
  static async toggleFormField(id, data) {
    return await fetch({
      url: urlKey + `/${id}/fields`,
      method: 'patch',
      data: data,
    })
  }
 
  static async sortFields( id, data ) {
    return await fetch({
      url: urlKey + `/${id}/fields/sort`,
      method: 'post',
      data: data,
    })
  }
  static async addField(id, data) {
    return await fetch({
      url: urlKey + `/${id}/fields`,
      method: 'put',
      data: data,
    })
  }

  static async duplicateField(id, data) {
    return await fetch({
      url: urlKey + `/${id}/duplicate-field`,
      method: 'put',
      data: data,
    })
  }
  
  static async submitObject(id, data) {
    return await fetch({
      url: `${urlKey}/${id}/submit`,
      method: 'post',
      data: data,
    })
  }
  static async createNestedForm(id, data) {
    return await fetch({
      url: `${urlKey}/${id}/nested-forms`,
      method: 'put',
      data: data,
    })
  }
  static async deleteNestedForm(id, formId) {
    return await fetch({
      url: urlKey + `/${id}/nested-forms/${formId}`,
      method: 'delete',
    })
  }
  static async updateNestedForm( id, formId, data ) {
    return await fetch({
      url: urlKey + `/${id}/nested-forms/${formId}`,
      method: 'put',
      data: data,
    })
  }
  static async assignUsers(id, data) {
    return await fetch({
      url: urlKey + `/assign-users/${id}`,
      method: 'patch',
      data: data,
    })
  }
  static async createSeparator(id, data) {
    return await fetch({
      url: `${urlKey}/${id}/separators`,
      method: 'put',
      data: data,
    })
  }
  static async deleteSeparator(id, separatorId) {
    return await fetch({
      url: urlKey + `/${id}/separators/${separatorId}`,
      method: 'delete',
    })
  }
  static async updateSeparator( id, separatorId, data ) {
    return await fetch({
      url: urlKey + `/${id}/separators/${separatorId}`,
      method: 'put',
      data: data,
    })
  }
  static async updateFieldTranslation( id, formId, data ) {
    return await fetch({
      url: urlKey + `/${id}/nested-forms-translation/${formId}`,
      method: 'put',
      data: data,
    })
  }
}