import fetch from 'auth/FetchInterceptor'

const urlKey = '/table-schemas';

export default class TableSchemaService {

  static async createObject(data) {
    return await fetch({
      url: urlKey,
      method: 'post',
      data: data,
    })
  }
  static async getObjects(params) {
    return await fetch({
      url: urlKey,
      method: 'get',
      params: params
    })
  }
  static async getObject(id, params) {
    return await fetch({
      url: urlKey + `/${id}`,
      method: 'get',
      params: params
    })
  }
  static async updateObject(id, data) {
    return await fetch({
      url: urlKey + `/${id}`,
      method: 'put',
      data: data,
    })
  }
  static async deleteObject(id) {
    return await fetch({
      url: urlKey + `/${id}`,
      method: 'delete',
    })
  }
  static async uploadXLSXFile(id, data) {
    return await fetch({
      url: urlKey + `/${id}/upload`,
      method: 'put',
      data: data,
      headers: { "Content-Type": "multipart/form-data" },

    })
  }
  static async addFieldInStructure(id, data) {
    return await fetch({
      url: urlKey + `/${id}/structures`,
      method: 'put',
      data: data,
    })
  }
  static async deleteStructure(id, structureId) {
    return await fetch({
      url: urlKey + `/${id}/structures/${structureId}`,
      method: 'delete',
    })
  }
  static async importFile(id, data) {
    return await fetch({
      url: urlKey + `/${id}/structures/import`,
      method: 'post',
      data: data,
      headers: { "Content-Type": "multipart/form-data" },

    })
  }
  static async createStructure(id, data) {
    return await fetch({
      url: urlKey + `/${id}/structures`,
      method: 'post',
      data: data,
    })
  }
  static async updateStructure(id, structureId, data) {
    return await fetch({
      url: urlKey + `/${id}/structures/${structureId}`,
      method: 'put',
      data: data,
    })
  }
  static async sortStructure(id, data) {
    return await fetch({
      url: urlKey + `/${id}/structures/sort`,
      method: 'post',
      data: data,
    })
  }
  static async updateAutoMatchFields(id, data) {
    return await fetch({
      url: urlKey + `/${id}/structures/updateAutoMatchFields`,
      method: 'post',
      data: data,
    })
  }
  static async importKobo(id, data) {
    return await fetch({
      url: urlKey + `/${id}/kobo/import`,
      method: 'post',
      data: data,
    })
  }
  static async assignUsers(id, data) {
    return await fetch({
      url: urlKey + `/assign-users/${id}`,
      method: 'patch',
      data: data,
    })
  }
  static async updateFieldTranslation(id, structureId, data) {
    return await fetch({
      url: urlKey + `/${id}/field-translate/${structureId}`,
      method: 'put',
      data: data,
    })
  }


}